import { useRef, useState } from "react";
import { useEffect } from "react";
import { FaPlay, FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import vid1 from "./videos/v1.mp4";
import vid2 from "./videos/v2.mp4";
import vid3 from "./videos/v3.mp4";
import vid4 from "./videos/v4.mp4";
import vid5 from "./videos/v5.mp4";
import ringtone from "./videos/sound.mp3";

const videos = [vid1, vid2, vid3, vid4, vid5];

const token = "49NXNkEpu7HRPZnQ8FeFZf5DYKPemdNDWUP2dMq324aJ";

const endpoint = `https://api.dexscreener.com/latest/dex/tokens/${token}`;

const demoMode = window.location.search.includes("demo");

function App() {
  const [vidIndex, setVideoIndex] = useState();

  const audioRef = useRef();

  const [sliderVal, setSliderVal] = useState(0);

  const [started, setStarted] = useState(false);

  const changeVidBasedOnCap = (val) => {
    console.log({ val });
    if (!val) {
      return setVideoIndex(0);
    }
    if (val > 15000000 && val <= 30000000) {
      return setVideoIndex(4);
    }
    if (val > 5000000 && val <= 15000000) {
      return setVideoIndex(3);
    }
    if (val > 1000000 && val <= 5000000) {
      return setVideoIndex(2);
    }
    if (val > 500000 && val <= 1000000) {
      return setVideoIndex(1);
    }
    if (val <= 500000) {
      return setVideoIndex(0);
    }
  };

  useEffect(() => {
    fetch(endpoint)
      .then((res) => res.json())
      .then((res) => {
        let val;
        for (let i = 0; i < res.pairs?.length; i++) {
          val = res.pairs[i].fdv;
          if (val > 0) {
            break;
          }
        }
        changeVidBasedOnCap(val);
      });
  }, []);

  const isMobile = window.innerWidth < 500;

  const fontSizes = {
    sm: isMobile ? "16px" : "18px",
    md: isMobile ? "18px" : "22px",
    lg: isMobile ? "20px" : "28px",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "hidden",
        backgroundColor: "#222222",
      }}
    >
      {!started && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 100,
          }}
          onClick={() => {
            setStarted(true);
            audioRef.current?.play();
          }}
        >
          <button
            style={{
              padding: "10px 20px",
              fontSize: "20px",
              color: "#fff",
              backgroundColor: "#222",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            See Your City <FaPlay />
          </button>
        </div>
      )}
      <audio
        ref={audioRef}
        src={ringtone}
        style={{ display: "none" }}
        preload="auto"
        autoPlay
        controls
      >
        Your browser does not support the audio element.
      </audio>
      {demoMode && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          <p style={{ color: "black" }}>Capital Value ({sliderVal})</p>
          <input
            type="range"
            min={0}
            max={30000000}
            value={sliderVal}
            placeholder="capital value"
            onChange={(e) => {
              changeVidBasedOnCap(e.target.value);
              setSliderVal(e.target.value);
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: isMobile ? "" : "#ffe0b3",
          height: isMobile ? "40vh" : window.innerHeight,
          width: "100%",
        }}
      >
        <video
          style={{
            width: isMobile ? "100%" : "100%",
            height: isMobile ? "40vh" : window.innerHeight,
          }}
          src={videos[vidIndex]}
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: isMobile ? "" : "15px",
          width: isMobile ? "100%" : "90%",
          gap: 5,
          padding: "10px 0",
          zIndex: 1,
        }}
      >
        {videos
          .filter((vid, i) => i !== vidIndex)
          .map((_, i) =>
            i < vidIndex ? (
              <video
                style={{
                  width: isMobile ? "22%" : "25%",
                  minWidth: isMobile ? "60px" : "60px",
                  border: "1px solid gray",
                }}
                src={videos[i]}
                autoPlay
                loop
                muted
                playsInline
              />
            ) : (
              <div
                style={{
                  position: "relative",
                  width: isMobile ? "22%" : "25%",
                  minWidth: isMobile ? "60px" : "100px",
                  border: "1px solid gray",
                }}
              >
                <video
                  style={{ opacity: "1%", width: "100%" }}
                  src={videos[i]}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: isMobile ? "3em" : "5em",
                    pointerEvents: "none",
                  }}
                >
                  ?
                </div>
              </div>
            )
          )}
      </div>
      <footer
        style={{
          textAlign: "center",
          left: "0",
          bottom: "0",
          width: isMobile ? "90%" : "90%",
          fontFamily: "Arial, sans-serif",
          fontSize: fontSizes.sm,
          color: "#fff",
          borderTop: "1px solid #e7e7e7",
        }}
      >
        <div style={{ textAlign: "left", borderBottom: "1px solid #e7e7e7" }}>
          <p style={{ fontSize: fontSizes.lg }}>
            <strong style={{ fontSize: "28px", textAlign: "center" }}>
              Who we are?
            </strong>
          </p>
          <p style={{ color: "#bbb" }}>
            <strong style={{ color: "white" }}>SolCity</strong> is a dynamic,
            blockchain-based game on the Solana network where the city's growth
            and development are directly influenced by the price movement.
          </p>
          <div
            style={{
              borderRadius: "12px 0 12px 0",
              border: "1px solid #e7e7e7",
              padding: "5px 10px",
              marginTop: "10px",
              width: "66%",
              background: "rgba(64, 74, 92, 0.4)",
            }}
          >
            <p>
              <strong style={{ fontSize: fontSizes.lg }}>
                Core Gameplay Features
              </strong>
            </p>
            <div style={{ margin: "12px" }}>
              <p>
                <strong style={{ fontSize: fontSizes.md }}>
                  1. Economic Influence
                </strong>
              </p>
              <ul style={{ color: "#bbb" }}>
                <li>
                  Players' trading activities directly impact the token price,
                  influencing the city's growth and advancement through
                  different <strong style={{ color: "white" }}>Eras</strong>.
                </li>
              </ul>

              <p>
                <strong style={{ fontSize: fontSizes.md }}>
                  2. City-Building Mechanics
                </strong>
              </p>
              <ul style={{ color: "#bbb" }}>
                <li>
                  The city's development is automated and tied to the token's
                  market performance, reflecting the collective trading efforts
                  of the players.
                </li>
                <li>
                  Each Era unlocks new building types, technologies, and visual
                  changes, providing a dynamic and evolving cityscape.
                </li>
              </ul>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                borderRadius: "12px 0 12px 0",
                border: "1px solid #e7e7e7",
                padding: "5px 10px",
                marginTop: "10px",
                width: "66%",
                background: "rgba(64, 74, 92, 0.4)",
              }}
            >
              <p>
                <strong style={{ fontSize: fontSizes.lg }}>
                  SolCity Tokenomics
                </strong>
              </p>
              <div style={{ margin: "12px" }}>
                <p style={{ color: "#bbb" }}>
                  <strong style={{ color: "white" }}>Total Supply:</strong>{" "}
                  <strong style={{ color: "white" }}>10,000,000</strong> SOLCITY
                </p>
                <p style={{ color: "#bbb" }}>
                  <strong style={{ color: "white" }}>
                    Circulating Supply:
                  </strong>{" "}
                  <strong style={{ color: "white" }}>5,000,000</strong> SOLCITY
                </p>
                <p style={{ color: "#bbb" }}>
                  <strong style={{ color: "white" }}>Allocation:</strong>
                </p>
                <ul style={{ color: "#bbb" }}>
                  <li>
                    <strong style={{ color: "white" }}>Advertising:</strong>{" "}
                    <strong style={{ color: "white" }}>500,000</strong> SOLCITY
                  </li>
                  <li>
                    <strong style={{ color: "white" }}>Team:</strong>{" "}
                    <strong style={{ color: "white" }}>500,000</strong> SOLCITY
                  </li>
                  <li>
                    <strong style={{ color: "white" }}>
                      Centralized Exchanges (CEX):
                    </strong>{" "}
                    <strong style={{ color: "white" }}>1,000,000</strong>{" "}
                    SOLCITY
                  </li>
                  <li>
                    <strong style={{ color: "white" }}>Burn:</strong>{" "}
                    <strong style={{ color: "white" }}>3,000,000</strong>{" "}
                    SOLCITY
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              borderRadius: "12px 0 12px 0",
              border: "1px solid #e7e7e7",
              padding: "5px 10px",
              marginTop: "10px",
              width: "66%",
              background: "rgba(64, 74, 92, 0.4)",
            }}
          >
            <p>
              <strong style={{ fontSize: fontSizes.md }}>Burn Mechanism</strong>
            </p>
            <div style={{ margin: "12px", color: "#bbb" }}>
              <p>
                Each phase passed in the game will result in the burning of{" "}
                <strong style={{ color: "white" }}>500,000</strong> SOLCITY
                tokens, reducing the total supply and potentially increasing the
                token's value.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                borderRadius: "12px 0 12px 0",
                border: "1px solid #e7e7e7",
                padding: "5px 10px",
                marginTop: "10px",
                width: "66%",
                background: "rgba(64, 74, 92, 0.4)",
              }}
            >
              <p>
                <strong style={{ fontSize: fontSizes.lg }}>Disclaimer</strong>
              </p>
              <div style={{ margin: "12px", color: "#bbb" }}>
                <p>
                  Players are responsible for their own trading decisions and
                  should carefully consider their risk tolerance and financial
                  situation before participating. It is recommended to conduct
                  thorough research and consult with financial advisors if
                  needed.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: fontSizes.md,
            fontWeight: "bold",
          }}
        >
          Follow Us
        </div>
        <div
          style={{
            margin: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="https://x.com/Scetoken?t=f5aRGd65pBzNEEBYQ-9P7w&s=09"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              margin: "0 10px",
              color: "#FFF",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineTwitter style={{ marginRight: "5px" }} />
            X.com
          </a>
          <a
            href="https://t.me/SolcityP"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              margin: "0 10px",
              color: "#1DA1F2",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaTelegramPlane style={{ marginRight: "5px" }} />
            Telegram
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
